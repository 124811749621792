import type { ItemSchema } from "@app/types";
import { ItemFilter, ListColumn } from "@app/types";
import { PlusIcon } from "@heroicons/react/20/solid";
import React, { useRef, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Drawer from "../../components/atom/Drawer";
import { FilterEnum } from "../../components/atom/FilterSingle";
import Loading from "../../components/atom/Loading";
import Pagination from "../../components/atom/Pagination";
import SearchField from "../../components/atom/SearchField";
import SelectedRows from "../../components/atom/SelectedRows";
import SortableColumn from "../../components/atom/SortableColumn";
import Form from "../../components/form/Form";
import { classNames } from "../../utils/dom";
import { useSelectAll } from "../../utils/selection";
const dictionary = {};
export default function List({
  children,
  items,
  PAGESIZE = 20,
  totalCount,
  columns,
  urlPrefix,
}: {
  children: React.ReactNode;
  items: any[];
  totalCount: number;
  PAGESIZE?: number;
  columns: ListColumn[];
  urlPrefix?: string;
}) {
  const selectAllRef = useRef();
  const { selected, setSelected, selection } = useSelectAll(
    selectAllRef,
    items
  );

  return (
    <div className="">
      <div className="grid grid-cols-1">
        <div className="row-start-1 flex flex-wrap justify-end my-2 space-x-4  text-sm">
          {/* <div className="relative mr-3 mb-3">
            <FilterText name="name" placeholder={t('Search items')} />
          </div> */}

          {children}
        </div>
        <div className="row-start-1 flex justify-end mb-6 text-sm">
          {/* <BulkActions
              selected={selected}
              selection={selection}
              data={r?.data}
            /> */}
        </div>
      </div>
      <SelectedRows
        selected={selected}
        setSelected={setSelected}
        selection={selection}
        totalCount={totalCount}
        pageSize={PAGESIZE}
      />
      <div className="overflow-hidden mb-10 border border-gray-200 sm:rounded-lg">
        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead>
              <tr className="bg-gray-50 text-left text-xs text-gray-900 uppercase tracking-wider">
                <th className="sticky left-0 z-10 px-4 py-4 bg-inherit font-medium">
                  <input
                    name="select-all"
                    type="checkbox"
                    className="mr-4 focus:ring-sportgreen-500 h-4 w-4 text-sportgreen-600 border-gray-300 rounded"
                    value="current-page"
                    checked={
                      selected.length > 0 &&
                      (selected.length >= PAGESIZE ||
                        selected.length === totalCount)
                    }
                    onChange={setSelected}
                    ref={selectAllRef.current}
                  />
                </th>
                {columns.map((column) => (
                  <th className="px-4 py-4 font-medium" key={column.name}>
                    {column.sortBy ? (
                      <SortableColumn field={column.sortBy}>
                        <>{column.name}</>
                      </SortableColumn>
                    ) : (
                      <>{column.name}</>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white">
              {items.map((item) => (
                <tr
                  key={item.id}
                  className={classNames(
                    { "bg-sportgreen-50": selected.includes(item.id) },
                    "text-sm text-gray-500 whitespace-nowrap bg-white hover:bg-sportgreen-50 border-t border-gray-100"
                  )}
                >
                  <td className="sticky left-0 z-10 max-w-xs sm:max-w-sm px-4 py-3 bg-inherit font-medium text-gray-900 text-left text-ellipsis overflow-hidden">
                    {selected.includes(item.id) && (
                      <div className="absolute inset-y-0 left-0 w-0.5 bg-sportgreen-600" />
                    )}
                    <input
                      name="intakeform"
                      type="checkbox"
                      className="mr-4 focus:ring-sportgreen-500 h-4 w-4 text-sportgreen-600 border-gray-300 rounded"
                      value={item.id}
                      checked={selected.includes(item.id)}
                      onChange={setSelected}
                    />
                  </td>
                  {columns.map((column) => (
                    <td key={column.id} className="px-4 py-3">
                      <Link
                        to={`${urlPrefix ? urlPrefix + "/" : ""}${item.id}`}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: column.parseFormData
                              ? column.parseFormData(item[column.id])
                              : column.parseExtraData
                              ? column.parseExtraData(item)
                              : item[column.id],
                          }}
                        />
                      </Link>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination totalCount={totalCount} pageSize={PAGESIZE} />
      </div>
    </div>
  );
}

function AddForm({
  refetch,
  schema,
  defaultValues = { data: {}, extraData: {} },
}: {
  refetch: () => void;
  schema: ItemSchema;
  defaultValues?: any;
}) {
  const [createItem] = schema.useCreateItemMutation();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const initialValues = schema.addInitialValues;
  if (defaultValues) {
    defaultValues?.data &&
      Object.keys(defaultValues?.data).forEach((key) => {
        initialValues[key] = defaultValues["data"][key];
      });
  }

  const submit = async (data: any) => {
    setLoading(true);
    const input = schema.createAddInputFromValues
      ? schema.createAddInputFromValues(data)
      : { input: schema.createInputFromValues(data) };
    console.log("data", data, input);
    await createItem({
      variables: input,
    });
    setLoading(false);
    setOpen(false);
    refetch();
  };
  return (
    <>
      <button
        onClick={() => setOpen(true)}
        className="border border-gray-200 rounded-md w-7 h-7 flex justify-center items-center hover:bg-gray-100 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sportgreen"
      >
        <PlusIcon className="h-5 w-5 text-gray-600" />
      </button>
      <Drawer title={`Add ${schema.name}`} open={open} setOpen={setOpen}>
        <Form
          form={schema.addForm}
          data={initialValues}
          dictionary={dictionary}
          extraData={defaultValues?.extraData || {}}
          loading={loading}
          save={submit}
          itemId={null}
        />
      </Drawer>
    </>
  );
}

function Filter({ filter }: { filter?: ItemFilter }) {
  if (!filter) {
    return null;
  }
  if (filter.type === "enum" && filter.enum) {
    return (
      <div className=" mr-3 mb-3">
        <FilterEnum name={filter.name} enumValues={filter.enum} />
      </div>
    );
  } else if (filter.type === "text") {
    return (
      <SearchField
        name={filter.name}
        placeholder={filter.placeholder || "Search..."}
      />
    );
  }

  return null;
}

function Filters({ filters }: { filters: ItemFilter[] }) {
  console.log(filters);
  return (
    <>
      {filters.map((filter) => {
        return <Filter key={filter?.name} filter={filter} />;
      })}
    </>
  );
}

function getGraphqlFilterFromFiltersAndSearchParams(
  schema: ItemSchema,
  searchParams: URLSearchParams,
  parentFilter?: any
) {
  const filters = schema.filters;
  const graphqlFilter: any = {};
  if (!filters && !parentFilter) {
    return null;
  }
  if (parentFilter) {
    Object.keys(parentFilter).forEach((key) => {
      graphqlFilter[key] = { equalTo: parentFilter[key] };
    });
  }
  console.log("found parentFilter", parentFilter);
  filters?.forEach((filter) => {
    const filterValue = searchParams.get(filter.name);
    if (filterValue) {
      if (filter.type === "enum") {
        graphqlFilter[filter.name] = { equalTo: filterValue };
      } else if (filter.type === "text") {
        if (filter.subtype === "like") {
          graphqlFilter[filter.name] = { likeInsensitive: `%${filterValue}%` };
        } else {
          const searchFilter = filterValue.split(" ");
          //join with + and :* to make it a full text search
          graphqlFilter[filter.name] = {
            matches: searchFilter.map((word) => `${word}:*`).join("+"),
          };
        }
      }
    }
  });
  //return null if no filters are set
  if (Object.keys(graphqlFilter).length === 0) {
    return null;
  }
  return graphqlFilter;
}

export function ItemList({
  schema,
  parentFilter,
  defaultValues,
  urlPrefix,
}: {
  schema: ItemSchema;
  parentFilter?: any;
  defaultValues?: any;
  urlPrefix?: string;
}) {
  // const TYPES = getOptionsFromEnum(ProductType);
  const PAGESIZE = 20;
  const [searchParams, setSearchParams] = useSearchParams();
  const offset = parseInt(searchParams.get("offset") || "0");
  const orderBy = searchParams.get("orderBy") || schema.defaultOrderBy;

  const filter = getGraphqlFilterFromFiltersAndSearchParams(
    schema,
    searchParams,
    parentFilter
  );
  console.log("list", schema.name, parentFilter, filter, orderBy, offset);
  const variables: any = {
    first: PAGESIZE,
    pagesize: PAGESIZE,
    offset: offset,
  };
  if (orderBy) {
    variables["orderBy"] = orderBy;
  }
  if (filter) {
    variables["filter"] = filter;
  }
  console.log("variables", variables);
  const { data, loading, refetch } = schema.useItemsQuery({
    variables: variables,
  });

  const { items, totalCount } = schema.parseItems(data);

  if (!items || loading) {
    return <Loading />;
  }
  console.log("list", items, totalCount, schema.addForm);
  return (
    <List
      items={items}
      totalCount={totalCount}
      PAGESIZE={PAGESIZE}
      columns={schema.listColumns}
      urlPrefix={urlPrefix}
    >
      <h2 className="font-semibold text-lg">{schema.plural}</h2>
      <div className="flex-grow row-start-1 flex flex-wrap justify-end space-x-4  text-sm">
        {schema.filters && <Filters filters={schema.filters} />}
      </div>

      {schema.addForm && (
        <AddForm
          refetch={refetch}
          schema={schema}
          defaultValues={defaultValues}
        />
      )}
    </List>
  );
}
