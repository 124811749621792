import type { FormFieldComponentProps, Form as FormType } from "@app/types";
import { FieldType } from "@app/types";
import { useState } from "react";
import { getTranslation } from "../../utils/schema";

import {
  BooleanInput,
  Field,
  ImageInput,
  Input,
  OptionsInput,
  ReferenceInput,
  Text,
} from "./Inputs";

export default function Form({
  itemId,
  form,
  data,
  dictionary,
  loading,
  extraData,
  refetch,
  save,
  actionName,
}: {
  form: FormType;
  data: any;
  extraData?: any;
  dictionary: any;
  loading: boolean;
  itemId: string | null;
  refetch?: () => void;
  save?: (data: any) => void;
  actionName?: string;
}) {
  const [formData, setFormData] = useState(data);
  const update = (field: string, value: any) => {
    setFormData((prev: any) => ({ ...prev, [field]: value }));
  };

  return (
    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 ">
      {form?.map(
        ({
          id,
          name,
          type,
          values,
          required,
          colspan,
          parseFormData,
          nullable,
          autocomplete,
          description,
          fieldProps,
          info,
        }) => {
          if (type === FieldType.text) {
            return (
              <Field colSpan={colspan || 3} key={name} info={info}>
                <Input
                  type="text"
                  name={id}
                  value={formData[id]}
                  required={required}
                  autoComplete={autocomplete}
                  parseFormData={parseFormData}
                  onChange={update}
                >
                  {getTranslation(dictionary, name)}
                </Input>
              </Field>
            );
          } else if (type === FieldType.number) {
            return (
              <Field colSpan={colspan || 3} key={name} info={info}>
                <Input
                  type="number"
                  name={id}
                  value={formData[id]}
                  required={required}
                  autoComplete={autocomplete}
                  parseFormData={parseFormData}
                  onChange={update}
                >
                  {getTranslation(dictionary, name)}
                </Input>
              </Field>
            );
          } else if (type === FieldType.email) {
            return (
              <Field colSpan={colspan || 3} key={name} info={info}>
                <Input
                  type="email"
                  name={id}
                  value={formData[id]}
                  required={required}
                  autoComplete={autocomplete}
                  parseFormData={parseFormData}
                  onChange={update}
                >
                  {getTranslation(dictionary, name)}
                </Input>
              </Field>
            );
          } else if (type === FieldType.phone) {
            return (
              <Field colSpan={colspan || 3} key={name} info={info}>
                <Input
                  type="tel"
                  name={id}
                  value={formData[id]}
                  required={required}
                  autoComplete={autocomplete}
                  parseFormData={parseFormData}
                  onChange={update}
                >
                  {getTranslation(dictionary, name)}
                </Input>
              </Field>
            );
          } else if (type === FieldType.datetime) {
            return (
              <Field colSpan={colspan || 3} key={name} info={info}>
                <Input
                  type="datetime-local"
                  name={id}
                  value={formData[id]}
                  required={required}
                  autoComplete={autocomplete}
                  parseFormData={parseFormData}
                  onChange={update}
                >
                  {getTranslation(dictionary, name)}
                </Input>
              </Field>
            );
          } else if (type === FieldType.boolean) {
            return (
              <Field colSpan={colspan || 2} key={name} info={info}>
                <BooleanInput
                  name={id}
                  checked={formData[id]}
                  description={description}
                  onChange={update}
                >
                  {getTranslation(dictionary, name)}
                </BooleanInput>
              </Field>
            );
          } else if (type === FieldType.select) {
            return (
              <Field colSpan={colspan || 6} key={name} info={info}>
                <OptionsInput
                  name={id}
                  value={formData[id]}
                  required={required}
                  options={values}
                  nullable={nullable}
                  onChange={update}
                >
                  {getTranslation(dictionary, name)}
                </OptionsInput>
              </Field>
            );
          } else if (type === FieldType.image) {
            return (
              <Field colSpan={colspan || 6} key={name} info={info}>
                <ImageInput
                  name={id}
                  value={extraData ? extraData[id] : null}
                  required={required}
                  onChange={update}
                  itemId={itemId}
                  fieldProps={fieldProps}
                >
                  {getTranslation(dictionary, name)}
                </ImageInput>
              </Field>
            );
          } else if (type === FieldType.reference) {
            return (
              <Field colSpan={colspan || 6} key={name} info={info}>
                <ReferenceInput
                  name={id}
                  value={formData[id]}
                  extraData={extraData}
                  required={required}
                  fieldProps={fieldProps}
                  onChange={update}
                >
                  {getTranslation(dictionary, name)}
                </ReferenceInput>
              </Field>
            );
          } else if (type === FieldType.label) {
            return (
              <Field colSpan={colspan || 6} key={name}>
                <Text value={extraData[id]} parseFormData={parseFormData}>
                  {getTranslation(dictionary, name)}
                </Text>
              </Field>
            );
          } else if (type === FieldType.component) {
            console.log(fieldProps);
            const Component =
              fieldProps?.component as React.ComponentType<FormFieldComponentProps>;
            return (
              <Field colSpan={colspan || 6} key={name} info={info}>
                <Component
                  id={id}
                  name={getTranslation(dictionary, name)}
                  data={extraData}
                  refetch={refetch}
                />
              </Field>
            );
          } else {
            throw Error(`Unknown field type ${type}`);
          }
        }
      )}
      {save && (
        <Field colSpan={6}>
          <button
            onClick={() => save(formData)}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sportgreen hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sportgreen disabled:opacity-50"
            disabled={loading}
          >
            {actionName || "Save"}
          </button>
        </Field>
      )}
    </div>
  );
}

export const FormWrapper = ({ children }: { children: any }) => {
  return (
    <div className="bg-white shadow sm:rounded-lg px-2 py-4">
      <div className="my-5 mx-4 grid grid-flow-row grid-cols-1 gap-y-4">
        {children}
      </div>
    </div>
  );
};
