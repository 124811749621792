import { useEffect, useState } from "react";
import LoadingIcon from "../../components/atom/LoadingIcon";
import { useBicycleValueByYear } from "./hooks/useBicycleValueByYear";

import { gql, useMutation } from "@apollo/client";

const UPDATE = gql`
  mutation ValueByYearMutation($nodeId: ID!, $patch: BicycleValueByYearPatch!) {
    updateBicycleValueByYearByNodeId(
      input: { nodeId: $nodeId, patch: $patch }
    ) {
      clientMutationId
    }
  }
`;

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function Cell({ data, updateData, year, priceFrom }) {
  const object = data.find(
    (node) => node.year === year && node.priceFrom === priceFrom
  );
  if (!object) {
    return null;
  }
  return (
    <input
      name="discountPct"
      max="100"
      min="0"
      type="number"
      value={object.discountPct}
      onChange={(e) => updateData("discountPct", object.nodeId, e.target.value)}
    />
  );
}

export default function ValueByYearList() {
  const [save] = useMutation(UPDATE);
  const { loading, valuesByYear } = useBicycleValueByYear();
  const [data, setData] = useState([]);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (!loading && data.length <= 0) {
      setData(
        valuesByYear.map((obj) => {
          return {
            nodeId: obj.nodeId,
            year: obj.year,
            priceFrom: obj.priceFrom,
            discountPct: obj.discountPct,
            updated: false,
          };
        })
      );
    }
  }, [data, loading, valuesByYear]);

  const updateData = (key, nodeId, value) => {
    const newData = data.map((obj) => {
      if (obj.nodeId === nodeId) {
        var newObj = {
          ...obj,
          [key]: value,
          updated: true,
        };
        return newObj;
      }
      return obj;
    });
    setData(newData);
  };

  const saveData = async (obj) => {
    console.log("save...", obj.nodeId);
    var variables = {
      nodeId: obj.nodeId,
      patch: { discountPct: Number(obj.discountPct) },
    };
    const form = await save({ variables });
    const result =
      form?.data?.updateBicycleValueByYearByNodeId?.clientMutationId;
    return result;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaving(true);
    try {
      data
        .filter((obj) => obj.updated)
        .map((obj) => {
          return saveData(obj);
        });
      const newData = data.map((obj) => {
        return {
          ...obj,
          updated: false,
        };
      });
      setData(newData);
    } catch (e) {
      alert(e.message);
    } finally {
      setSaving(false);
    }
  };

  const years = data
    .map((node) => node.year)
    .filter(onlyUnique)
    .sort();
  const pricesFrom = data
    .map((node) => node.priceFrom)
    .filter(onlyUnique)
    .sort();

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Bicycle value by year
          </h1>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="shadow-sm ring-1 ring-black ring-opacity-5">
              {loading && (
                <div className="text-center">
                  <div className="inline-flex">
                    <LoadingIcon className="h-10 w-10" />
                  </div>
                </div>
              )}
              <form onSubmit={handleSubmit}>
                {!loading && (
                  <table
                    className="min-w-full border-separate"
                    style={{ borderSpacing: 0 }}
                  >
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                        >
                          Price From / Years
                        </th>
                        {years.map((object) => (
                          <th
                            key={`year-header-${object}`}
                            scope="col"
                            className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                          >
                            {object}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {pricesFrom.map((priceFrom, pricesFromIdx) => (
                        <tr key={`priceFronRow-${priceFrom}`}>
                          <td
                            className={classNames(
                              pricesFromIdx !== valuesByYear.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8 bg-gray-50 bg-opacity-75 "
                            )}
                          >
                            {priceFrom}
                          </td>

                          {years.map((year) => (
                            <td
                              key={`priceFrom-${priceFrom}-year-${year}`}
                              className="sticky top-0 z-10 border-b border-gray-300 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                            >
                              <Cell
                                data={data}
                                updateData={updateData}
                                year={year}
                                priceFrom={priceFrom}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                <div className="my-4 w-full px-6">
                  <button
                    disabled={loading || saving}
                    type="submit"
                    className="inline-flex items-center justify-center disabled:bg-sportgrey disabled:text-black px-4 py-2 border border-transparent font-medium rounded-md text-white bg-sportgreen shadow-sportgreen/50 hover:shadow-lg focus:rshadow-lg  sm:text-sm w-full"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
