import { Link, NavLink } from "react-router-dom";
import BikeIcon from "../../media/images/bike.svg";
/* This example requires Tailwind CSS v2.0+ */

import {
  QuestionMarkCircleIcon,
  ShoppingCartIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";

import type {
  BicycleIntakeForm,
  BicyclePriceQuery,
  Inventory,
  Order,
} from "@app/graphql/pim";
import { useUpdateBicyclePriceQueryPimMutation } from "@app/graphql/pim";
import { PencilIcon } from "@heroicons/react/24/solid";

import { useState } from "react";
import { BicycleSearch } from "../calculator/Form";
import LoadingInline from "./LoadingInline";
import Popup from "./Popup";

function BikeEditPopup({
  bicyclePriceQuery,

  open,
  setOpen,
}: {
  bicyclePriceQuery: BicyclePriceQuery;
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const [bicycleId, setBicycleId] = useState<number | null>(null);
  const [bicycleName, setBicycleName] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [save] = useUpdateBicyclePriceQueryPimMutation();

  const handleSave = async () => {
    if (loading) return;
    if (bicycleId && bicycleName) {
      setLoading(true);
      await save({
        variables: {
          id: bicyclePriceQuery.id,
          patch: {
            bicycleId,
          },
        },
      });
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <Popup open={open} setOpen={setOpen} title="Rediger sykkel">
      <div className="min-h-64 h-80">
        <BicycleSearch
          bicycleId={bicycleId}
          setBicycleId={setBicycleId}
          bicycleName={bicycleName}
          setBicycleName={setBicycleName}
        />
        {bicycleId &&
          (!loading ? (
            <button
              onClick={handleSave}
              className="mt-4 rounded-lg p-2 bg-sportgreen text-white w-full"
            >
              Lagre
            </button>
          ) : (
            <LoadingInline />
          ))}
      </div>
    </Popup>
  );
}

export default function BikeHeader({
  name,
  bicyclePriceQuery,
  bicycleIntakeForm,
  inventory,
  order,
}: {
  name?: string;
  bicyclePriceQuery?: BicyclePriceQuery;
  bicycleIntakeForm?: BicycleIntakeForm;
  inventory?: Inventory;
  order?: Order;
}) {
  const [openEditBike, setOpenEditBike] = useState(false);
  const steps = [
    {
      id: "01",
      icon: QuestionMarkCircleIcon,
      name: "Prisforespørsel",
      baseUrl: "../../pricequery",
      object: bicyclePriceQuery,
    },
    {
      id: "02",
      icon: TruckIcon,
      name: "Inntak",
      baseUrl: "../../intake",
      object: bicycleIntakeForm,
    },
    {
      id: "03",
      icon: ShoppingCartIcon,
      name: "Salgsplakat",
      baseUrl: "../../inventory",
      object: inventory,
    },
    {
      id: "04",
      icon: TruckIcon,
      name: "Order",
      baseUrl: "../../order",
      object: order,
    },
  ];

  return (
    <div className="bg-white border-b-gray-200 border-b ">
      <div className="w-full  px-2  text-xl text-black flex gap-1 ">
        <div>
          <img src={BikeIcon} alt="" className="mr-2 inline-block w-10 m-1" />
        </div>
        <div className="flex flex-col">
          <div>
            <Link
              className="hover:underline"
              to={`../../bicycles/${bicyclePriceQuery?.bicycle?.id}`}
            >
              {name || "Laster skjema..."}
            </Link>
          </div>
          <div className="text-base text-sportgreyweb ">
            SKU:{bicycleIntakeForm?.sku ?? "–"} ,{" "}
            <Link
              className="text-base text-oxford-blue hover:underline "
              to={`../../protocol/${bicyclePriceQuery?.purchaseProtocol?.sequence}`}
            >
              PP:{bicyclePriceQuery?.purchaseProtocol?.sequence ?? "–"}
            </Link>
          </div>
        </div>
        {bicyclePriceQuery && !inventory && (
          <div className="flex-grow flex justify-end">
            <BikeEditPopup
              bicyclePriceQuery={bicyclePriceQuery}
              open={openEditBike}
              setOpen={setOpenEditBike}
            />
            <button
              className="text-base "
              onClick={() => setOpenEditBike(!openEditBike)}
            >
              <PencilIcon className="h-5 w-5 inline-block" />
            </button>
          </div>
        )}
      </div>
      <nav aria-label="Progress">
        <ol role="list" className=" md:flex md:divide-y-0">
          {steps.map((step, stepIdx) => (
            <li key={step.name} className="relative md:flex-1 md:flex">
              {step.object ? (
                <NavLink
                  to={`${step?.baseUrl}/${step?.object.id}`}
                  className={({ isActive }) =>
                    isActive
                      ? "px-6 py-4 flex items-center text-sm  text-sportgreen font-medium group-hover:bg-spanish-green"
                      : "px-6 py-4 flex items-center text-sm font-medium group-hover:bg-spanish-green "
                  }
                  aria-current="step"
                >
                  {({ isActive }) => (
                    <>
                      {isActive ? (
                        <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-sportgreen rounded-full group-hover:bg-spanish-green">
                          <step.icon
                            className="w-6 h-6 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      ) : (
                        <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-white rounded-full group-hover:bg-spanish-green border-2 border-sportgreyweb ">
                          <step.icon
                            className="w-6 h-6 text-sportblack"
                            aria-hidden="true"
                          />
                        </span>
                      )}

                      <span className="ml-4 text-sm font-bold  ">
                        {step.name}
                      </span>
                    </>
                  )}
                </NavLink>
              ) : (
                <div className="group flex items-center">
                  <span className="px-6 py-4 flex items-center text-sm font-medium">
                    <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-white rounded-full border-2 border-gray-300 ">
                      <step.icon
                        className="w-6 h-6 text-gray-300"
                        aria-hidden="true"
                      />
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-500">
                      {step.name}
                    </span>
                  </span>
                </div>
              )}

              {stepIdx !== 0 ? (
                <div
                  className="hidden absolute top-0 left-0 w-3 inset-0 lg:block"
                  aria-hidden="true"
                >
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 12 82"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0.5 0V31L10.5 41L0.5 51V82"
                      stroke="currentcolor"
                      vectorEffect="non-scaling-stroke"
                    />
                  </svg>
                </div>
              ) : null}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
}
